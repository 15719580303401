.footer {
  @include span(12 of 12);

  &__text {
    @include span(12 of 12);
    font-family: $font-primary;
    font-size: rem(11px);
    line-height: rem(13px);
    color: $color-black;
  }

  &__link {
    color: $color-cobalt;

    &:hover {
      font-style: italic;
    }
  }
}
