.share {
  margin-bottom: rem(16px);

  &__item {
    display: inline-block;
    font-family: $font-secondary;
    font-size: rem(14px);
    line-height: rem(19px);
    color: $color-flush-orange;
    margin-right: rem(16px);

    &:last-child {
      margin-right: 0;
    }
  }

  &__item-link {
    color: $color-flush-orange;

    &:hover {
      font-style: italic;
    }
  }
}
