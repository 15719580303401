.comment {
  &__item {
    @include span(12 of 12 inside);
    margin-bottom: rem(16px);
    font-family: $font-secondary;
    font-size: rem(14px);
    line-height: rem(19px);
    color: $color-black;
  }

  &__form {
    @include span(12 of 12 inside);

    label {
      font-family: $font-primary;
      font-size: rem(14px);
      font-weight: bold;
      line-height: rem(19px);
      color: $color-black;
    }

    input,
    textarea {
      margin-bottom: rem(16px);
    }
  }

  &__popup {
    position: relative;
    width: rem(200px);
    padding: rem(16px);
    background-color: $color-flush-orange;
    color: $color-white;
    position: absolute;
    right: rem(12px);
    top: rem(600px);

    h2 {
      font-family: $font-primary;
      font-size: rem(14px);
      font-weight: bold;
      line-height: rem(19px);
      color: $color-white;
    }

    p {
      margin-bottom: rem(16px);
      font-family: $font-secondary;
      font-size: rem(14px);
      line-height: rem(19px);
      color: $color-white;
    }
  }

  &__return {
    @include span(12 of 12 inside);
    margin: rem(16px) 0;
    font-family: $font-primary;
    font-size: rem(14px);
    font-weight: bold;
    line-height: rem(19px);
    color: $color-flush-orange;

    &:hover {
      font-style: italic;
    }
  }

  &__popup-close {
    position: absolute;
    top: rem(16px);
    right: rem(16px);
    font-family: $font-primary;
    font-size: rem(14px);
    font-weight: bold;
    line-height: rem(19px);
    color: $color-white;
  }
}
