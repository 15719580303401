.header {
  @include span(12 of 12 inside);
  margin-bottom: rem(16px);

  &__primary-block {
    @include span(12 of 12 inside);
    position: relative;
    margin-bottom: rem(16px);

    @include ml-up {
      @include span(4 of 12 inside);
      margin-bottom: 0;
    }
  }

  &__secondary-block {
    @include span(12 of 12 inside);
    position: relative;

    @include ml-up {
      @include span(8 of 12 inside);
    }
  }

  &__tertiary-block {
    @include span(12 of 12 inside);
  }

  &__logo-wrapper {
    @include span(12 of 12 inside no-gutters);
  }

  &__logo {
    width: rem(83px);
    height: rem(118px);

    &:hover {
      .header__logo-shape {
        fill-opacity: 0;
        stroke-width: rem(2px);
      }
    }
  }

  &__logo-link {
    display: inline-block;
  }

  &__logo-shape {
    stroke-width: 0;
    fill-opacity: 1;
    transition: all .3s $transition-primary;

    &--primary {
      stroke: $color-cobalt;
      fill: $color-cobalt;
    }

    &--secondary {
      stroke: $color-flush-orange;
      fill: $color-flush-orange;
    }
  }

  &__description-wrapper {
    @include span(12 of 12 inside no-gutters);
  }

  &__description {
    @include span(12 of 12 inside);
    font-family: $font-primary;
    font-size: rem(14px);
    line-height: rem(18px);
    color: $color-black;

    &--primary {
      @include ml-up {
        @include span(6 of 12 inside);
        padding-left: 0;
      }
    }

    &--secondary {
      margin-top: rem(24px);
    }

    h2 {
      font-weight: bold;
    }
  }

  &__icon-wrapper {
    position: absolute;
    top: 0;
    right: rem(5px);
    cursor: pointer;
  }
}
