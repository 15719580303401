.gallery {
  @include span(12 of 12 inside no-gutters);
  display: flex;
  flex-wrap: wrap;
  padding: 0 rem(8px);

  &__item {
    @include span(12 of 12 inside no-gutters);
    position: relative;
    margin: rem(8px) 0;

    @include ml-up {
      @include span(6 of 12);
      padding: 0;
    }

    @include lg-up {
      @include span(4 of 12);
    }

    &:hover {
      &:before {
        top: 0;
      }

      .gallery__item-info {
        opacity: 1;
        transition: opacity .3s $transition-primary .15s;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 100%;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($color-cobalt, .8);
      transition: top .3s $transition-primary;
    }
  }

  &__item-info {
    @include span(12 of 12 inside);
    position: absolute;
    padding: rem(16px);
    opacity: 0;
    transition: opacity .3s $transition-primary;
  }

  &__item-title {
    @include span(12 of 12 inside no-gutters);
    font-family: $font-primary;
    font-size: rem(18px);
    line-height: rem(22px);
    color: $color-white;
  }

  &__item-subtitle {
    @include span(12 of 12 inside no-gutters);
    font-family: $font-secondary;
    font-size: rem(18px);
    line-height: rem(22px);
    color: $color-white;
  }

  &__item-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__mobile-item-info {
    @include span(12 of 12 inside);

    @include ml-up {
      display: none;
    }

    h2 {
      @include span(12 of 12 inside);
      font-family: $font-primary;
      font-size: rem(14px);
      font-weight: bold;
      line-height: rem(19px);
      color: $color-black;
    }

    p,
    div {
      @include span(12 of 12 inside);
      margin-bottom: rem(16px);
      font-family: $font-secondary;
      font-size: rem(14px);
      line-height: rem(19px);
      color: $color-black;
    }
  }
}
