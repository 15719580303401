.carousel {
  @include span(12 of 12);
  position: relative;

  &__navigation {
    position: absolute;
    top: 53%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
  }

  &__navigation-previous {
    @include span(6 of 12);
  }

  &___navigation-next {
    @include span(6 of 12);
    text-align: right;
  }

  &__dot-container {
    position: absolute;
    right: rem(16px);
    bottom: rem(16px);
    left: rem(16px);
  }

  &__dot-item {
    float: left;
    width: rem(12px);
    height: rem(12px);
    margin-right: rem(8px);
    border-radius: 50%;
    background-color: $color-mercury;

      &:last-of-type {
        margin-right: 0;
      }

      &.active {
        background-color: $color-cobalt;
      }
    }

  &__control {
    display: block;
    position: relative;
    width: rem(30px);
    height: rem(30px);
    color: $color-white;
    background-color: $color-cobalt;
    border-radius: 50%;

    &:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      font-family: $font-primary;
      font-size: rem(21px);
      line-height: rem(24px);
      transform: translateY(-50%) translateX(-50%);
    }

    &--previous {
      margin-left: rem(10px);

      &:before {
        content: '<';
      }
    }

    &--next {
      float: right;
      margin-right: rem(10px);

      &:before {
        content: '>';
      }
    }
  }

  &__control-text {
    position: absolute;
    left: rem(9999px);
  }

  &__media {
    &--decorated {
      border: rem(1px) solid $color-cobalt;
    }
  }
}
