$font-primary: Arial, 'Helvetica Neue', Helvetica, sans-serif;
$font-secondary: 'pt_serifregular', serif;

@font-face {
  font-family: 'pt_serifbold';
  src: url('../fonts/pt_serif-web-bold-webfont.woff2') format('woff2'),
       url('../fonts/pt_serif-web-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pt_serifbold_italic';
  src: url('../fonts/pt_serif-web-bolditalic-webfont.woff2') format('woff2'),
       url('../fonts/pt_serif-web-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pt_serifitalic';
  src: url('../fonts/pt_serif-web-italic-webfont.woff2') format('woff2'),
       url('../fonts/pt_serif-web-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pt_serifregular';
  src: url('../fonts/pt_serif-web-regular-webfont.woff2') format('woff2'),
       url('../fonts/pt_serif-web-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

::selection {
  color: $color-white;
  background-color: $color-cobalt;
}

a {
  text-decoration: none;
}
