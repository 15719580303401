.page {
  &--primary {
    @include ml-up {
      @include pattern-dot-primary;
      background-size: rem(4px);
    }
  }

  &--secondary {
    @include ml-up {
      @include pattern-dot-secondary;
      background-size: rem(4px);
    }
  }

  &__container {
    max-width: rem(1090px);
    position: relative;
    overflow: hidden;
    margin: rem(16px);
    padding: rem(16px) 0;
    background-color: $color-white;

    @include xl-up {
      margin: rem(16px) auto;
    }
  }

  &__outer-wrapper {
    @include span(12 of 12);
  }

  &__inner-wrapper {
    @include span(12 of 12 inside no-gutters);

    @include lg-up {
      @include span(8 of 12 inside no-gutters);
      @include push(4 of 12 inside no-gutters);
    }
  }

  &__divider {
    @include span(12 of 12);
    margin-bottom: rem(16px);
    border: none;
    height: rem(1px);

    &--primary {
      background-color: $color-cobalt;
    }

    &--secondary {
      background-color: $color-flush-orange;
    }
  }

  &__media-wrapper {
    @include span(12 of 12);
    margin-bottom: rem(16px);
  }

  &__content-wrapper {
    @include span(12 of 12);

    @include ml-up {
      @include span(7 of 12);
      @include push(4 of 12);
    }

    @include lg-up {
      @include span(8 of 12);
      @include push(0 of 12);
    }

    @include xl-up {
      @include span(9 of 12);
      @include push(0 of 12);
    }
  }

  &__content {
    @include span(12 of 12);

    img + img {
      margin-top: rem(16px);
    }

    h2 {
      font-family: $font-primary;
      font-size: rem(14px);
      font-weight: bold;
      line-height: rem(19px);
      color: $color-black;
    }

    p,
    div {
      margin-bottom: rem(16px);
      font-family: $font-secondary;
      font-size: rem(14px);
      line-height: rem(19px);
      color: $color-black;
    }

    a:not([class]) {
      color: $color-cobalt;

      &:hover {
        font-style: italic;
      }
    }
  }

  &__list {
    margin-bottom: rem(16px);
  }

  &__list-item {
    font-family: $font-secondary;
    font-size: rem(14px);
    line-height: rem(19px);
    color: $color-black;
  }

  &__list-item-link {
    color: $color-cobalt;

    &:hover {
      font-style: italic;
    }
  }

  &__column-primary {
    @include span(12 of 12 inside);

    @include lg-up {
      @include span(3 of 12 inside);
    }
  }

  &__column-secondary {
    @include span(12 of 12 inside);

    @include lg-up {
      @include span(6 of 12 inside);
    }

    .page__divider {
      &:last-of-type {
        display: none;
      }
    }
  }

  &__column-tertiary {
    @include span(12 of 12 inside);

    @include lg-up {
      @include span(3 of 12 inside);
    }
  }

  &__column-tertiary-partial {
    @include span(12 of 12 inside);
    margin-bottom: rem(16px);

    @include lg-up {
      @include span(6 of 12 inside);
    }
  }

  &__post-title {
    font-family: $font-primary;
    font-size: rem(14px);
    font-weight: bold;
    line-height: rem(19px);
    color: $color-flush-orange;
  }

  &__post-link {
    @include span(12 of 12 inside);
    margin-bottom: rem(16px);
    color: $color-flush-orange;

    &:hover {
      font-style: italic;
    }
  }

  &__post-archive {
    @include span(12 of 12 inside no-gutters);
    margin-bottom: rem(16px);
  }

  &__post-archive-item {
    @include span(12 of 12 inside);
    padding-left: 0;
  }

  &__post-archive-title {
    @include span(12 of 12 inside);
    font-family: $font-primary;
    font-size: rem(14px);
    font-weight: bold;
    line-height: rem(19px);
    color: $color-flush-orange;

    &:hover {
      font-style: italic;
    }
  }

  &__post-archive-year {
    @include span(12 of 12 inside);
    margin-top: rem(16px);
    font-family: $font-primary;
    font-size: rem(14px);
    font-weight: bold;
    line-height: rem(19px);
    color: $color-black;
  }

  &__post-archive-month {
    @include span(12 of 12 inside);
    font-family: $font-secondary;
    font-size: rem(14px);
    line-height: rem(19px);
    color: $color-flush-orange;

    &:hover {
      font-style: italic;
    }
  }

  &__side-link {
    @include span(12 of 12 inside);
    font-family: $font-primary;
    font-size: rem(14px);
    font-weight: bold;
    line-height: rem(19px);
    color: $color-flush-orange;

    &:hover {
      font-style: italic;
    }
  }
}
