/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/meyer-reset/stylesheets/_meyer-reset.scss */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* line 16, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/meyer-reset/stylesheets/_meyer-reset.scss */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

/* line 19, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/meyer-reset/stylesheets/_meyer-reset.scss */
body {
  line-height: 1; }

/* line 22, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/meyer-reset/stylesheets/_meyer-reset.scss */
ol, ul {
  list-style: none; }

/* line 25, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/meyer-reset/stylesheets/_meyer-reset.scss */
blockquote, q {
  quotes: none; }

/* line 29, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/meyer-reset/stylesheets/_meyer-reset.scss */
blockquote:before, blockquote:after {
  content: "";
  content: none; }

/* line 35, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/meyer-reset/stylesheets/_meyer-reset.scss */
q:before, q:after {
  content: "";
  content: none; }

/* line 40, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/meyer-reset/stylesheets/_meyer-reset.scss */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/**
 * Owl Carousel v2.1.1
 * Copyright 2013-2016 David Deutsch
 * Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
 */
/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0); }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel.owl-loaded {
  display: block; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel.owl-hidden {
  opacity: 0; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel.owl-refresh .owl-item {
  display: none; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel.owl-rtl {
  direction: rtl; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.no-js .owl-carousel {
  display: block; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .owl-animated-in {
  z-index: 0; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .owl-animated-out {
  z-index: 1; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-height {
  transition: height .5s ease-in-out; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .owl-item img.owl-lazy {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3, 1.3);
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease; }

/* line 6, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/bower_components/owl.carousel/dist/assets/owl.carousel.min.css */
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/* line 1, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_base/_main.scss */
* {
  box-sizing: border-box; }

/* line 5, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_base/_main.scss */
html {
  font-size: 62.5%;
  height: 100%; }

/* line 10, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_base/_main.scss */
body {
  font-size: 10px;
  height: 100%; }

/* line 15, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_base/_main.scss */
img {
  height: auto;
  width: 100%; }

/* line 20, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_base/_main.scss */
h2,
p {
  -webkit-font-smoothing: antialiased; }

/* line 25, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_base/_main.scss */
button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  appearance: none; }

@font-face {
  font-family: 'pt_serifbold';
  src: url("../fonts/pt_serif-web-bold-webfont.woff2") format("woff2"), url("../fonts/pt_serif-web-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'pt_serifbold_italic';
  src: url("../fonts/pt_serif-web-bolditalic-webfont.woff2") format("woff2"), url("../fonts/pt_serif-web-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'pt_serifitalic';
  src: url("../fonts/pt_serif-web-italic-webfont.woff2") format("woff2"), url("../fonts/pt_serif-web-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'pt_serifregular';
  src: url("../fonts/pt_serif-web-regular-webfont.woff2") format("woff2"), url("../fonts/pt_serif-web-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/* line 36, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_base/_typography.scss */
::selection {
  color: #fff;
  background-color: #003da5; }

/* line 41, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_base/_typography.scss */
a {
  text-decoration: none; }

/* line 2, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_base/_print.scss */
.print__block {
  display: none; }

/* line 2, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_base/_icons.scss */
.icon__menu-item {
  fill: #003da5; }

/* line 1, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_footer.scss */
.footer {
  width: 98.33333%;
  float: left;
  margin-left: 0.83333%;
  margin-right: 0.83333%; }
  /* line 4, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_footer.scss */
  .footer__text {
    width: 98.33333%;
    float: left;
    margin-left: 0.83333%;
    margin-right: 0.83333%;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.1rem;
    line-height: 1.3rem;
    color: #000; }
  /* line 12, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_footer.scss */
  .footer__link {
    color: #003da5; }
    /* line 15, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_footer.scss */
    .footer__link:hover {
      font-style: italic; }

/* line 1, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_gallery.scss */
.gallery {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.8rem; }
  /* line 7, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_gallery.scss */
  .gallery__item {
    width: 100%;
    float: left;
    position: relative;
    margin: 0.8rem 0; }
    @media (min-width: 601px) {
      /* line 7, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_gallery.scss */
      .gallery__item {
        width: 48.33333%;
        float: left;
        margin-left: 0.83333%;
        margin-right: 0.83333%;
        padding: 0; } }
    @media (min-width: 771px) {
      /* line 7, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_gallery.scss */
      .gallery__item {
        width: 31.66667%;
        float: left;
        margin-left: 0.83333%;
        margin-right: 0.83333%; } }
    /* line 22, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_gallery.scss */
    .gallery__item:hover:before {
      top: 0; }
    /* line 26, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_gallery.scss */
    .gallery__item:hover .gallery__item-info {
      opacity: 1;
      transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.15s; }
    /* line 32, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_gallery.scss */
    .gallery__item:before {
      content: '';
      position: absolute;
      top: 100%;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 61, 165, 0.8);
      transition: top 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
  /* line 44, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_gallery.scss */
  .gallery__item-info {
    width: 100%;
    float: left;
    padding-left: 0.83333%;
    padding-right: 0.83333%;
    position: absolute;
    padding: 1.6rem;
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
  /* line 52, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_gallery.scss */
  .gallery__item-title {
    width: 100%;
    float: left;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: #fff; }
  /* line 60, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_gallery.scss */
  .gallery__item-subtitle {
    width: 100%;
    float: left;
    font-family: "pt_serifregular", serif;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: #fff; }
  /* line 68, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_gallery.scss */
  .gallery__item-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  /* line 76, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_gallery.scss */
  .gallery__mobile-item-info {
    width: 100%;
    float: left;
    padding-left: 0.83333%;
    padding-right: 0.83333%; }
    @media (min-width: 601px) {
      /* line 76, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_gallery.scss */
      .gallery__mobile-item-info {
        display: none; } }
    /* line 83, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_gallery.scss */
    .gallery__mobile-item-info h2 {
      width: 100%;
      float: left;
      padding-left: 0.83333%;
      padding-right: 0.83333%;
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.9rem;
      color: #000; }
    /* line 92, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_gallery.scss */
    .gallery__mobile-item-info p,
    .gallery__mobile-item-info div {
      width: 100%;
      float: left;
      padding-left: 0.83333%;
      padding-right: 0.83333%;
      margin-bottom: 1.6rem;
      font-family: "pt_serifregular", serif;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: #000; }

/* line 1, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
.header {
  width: 100%;
  float: left;
  padding-left: 0.83333%;
  padding-right: 0.83333%;
  margin-bottom: 1.6rem; }
  /* line 5, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
  .header__primary-block {
    width: 100%;
    float: left;
    padding-left: 0.83333%;
    padding-right: 0.83333%;
    position: relative;
    margin-bottom: 1.6rem; }
    @media (min-width: 601px) {
      /* line 5, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
      .header__primary-block {
        width: 33.33333%;
        float: left;
        padding-left: 0.83333%;
        padding-right: 0.83333%;
        margin-bottom: 0; } }
  /* line 16, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
  .header__secondary-block {
    width: 100%;
    float: left;
    padding-left: 0.83333%;
    padding-right: 0.83333%;
    position: relative; }
    @media (min-width: 601px) {
      /* line 16, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
      .header__secondary-block {
        width: 66.66667%;
        float: left;
        padding-left: 0.83333%;
        padding-right: 0.83333%; } }
  /* line 25, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
  .header__tertiary-block {
    width: 100%;
    float: left;
    padding-left: 0.83333%;
    padding-right: 0.83333%; }
  /* line 29, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
  .header__logo-wrapper {
    width: 100%;
    float: left; }
  /* line 33, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
  .header__logo {
    width: 8.3rem;
    height: 11.8rem; }
    /* line 38, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
    .header__logo:hover .header__logo-shape {
      fill-opacity: 0;
      stroke-width: 0.2rem; }
  /* line 45, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
  .header__logo-link {
    display: inline-block; }
  /* line 49, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
  .header__logo-shape {
    stroke-width: 0;
    fill-opacity: 1;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
    /* line 54, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
    .header__logo-shape--primary {
      stroke: #003da5;
      fill: #003da5; }
    /* line 59, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
    .header__logo-shape--secondary {
      stroke: #ff8200;
      fill: #ff8200; }
  /* line 65, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
  .header__description-wrapper {
    width: 100%;
    float: left; }
  /* line 69, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
  .header__description {
    width: 100%;
    float: left;
    padding-left: 0.83333%;
    padding-right: 0.83333%;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #000; }
    @media (min-width: 601px) {
      /* line 76, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
      .header__description--primary {
        width: 50%;
        float: left;
        padding-left: 0.83333%;
        padding-right: 0.83333%;
        padding-left: 0; } }
    /* line 83, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
    .header__description--secondary {
      margin-top: 2.4rem; }
    /* line 87, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
    .header__description h2 {
      font-weight: bold; }
  /* line 92, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_header.scss */
  .header__icon-wrapper {
    position: absolute;
    top: 0;
    right: 0.5rem;
    cursor: pointer; }

@media (min-width: 601px) {
  /* line 2, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
  .page--primary {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4IDgiPiAgPGNpcmNsZSBjeD0iMiIgY3k9IjIiIHI9IjIiIGZpbGw9IiMxYTQ1OWQiLz48L3N2Zz4=);
    background-size: 0.4rem; } }

@media (min-width: 601px) {
  /* line 9, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
  .page--secondary {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4IDgiPiAgPGNpcmNsZSBjeD0iMiIgY3k9IjIiIHI9IjIiIGZpbGw9IiNmZjgyMDAiLz48L3N2Zz4=);
    background-size: 0.4rem; } }

/* line 16, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__container {
  max-width: 109rem;
  position: relative;
  overflow: hidden;
  margin: 1.6rem;
  padding: 1.6rem 0;
  background-color: #fff; }
  @media (min-width: 981px) {
    /* line 16, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
    .page__container {
      margin: 1.6rem auto; } }

/* line 29, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__outer-wrapper {
  width: 98.33333%;
  float: left;
  margin-left: 0.83333%;
  margin-right: 0.83333%; }

/* line 33, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__inner-wrapper {
  width: 100%;
  float: left; }
  @media (min-width: 771px) {
    /* line 33, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
    .page__inner-wrapper {
      width: 66.66667%;
      float: left;
      margin-left: 33.33333%; } }

/* line 42, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__divider {
  width: 98.33333%;
  float: left;
  margin-left: 0.83333%;
  margin-right: 0.83333%;
  margin-bottom: 1.6rem;
  border: none;
  height: 0.1rem; }
  /* line 48, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
  .page__divider--primary {
    background-color: #003da5; }
  /* line 52, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
  .page__divider--secondary {
    background-color: #ff8200; }

/* line 57, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__media-wrapper {
  width: 98.33333%;
  float: left;
  margin-left: 0.83333%;
  margin-right: 0.83333%;
  margin-bottom: 1.6rem; }

/* line 62, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__content-wrapper {
  width: 98.33333%;
  float: left;
  margin-left: 0.83333%;
  margin-right: 0.83333%; }
  @media (min-width: 601px) {
    /* line 62, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
    .page__content-wrapper {
      width: 56.66667%;
      float: left;
      margin-left: 0.83333%;
      margin-right: 0.83333%;
      margin-left: 34.16667%; } }
  @media (min-width: 771px) {
    /* line 62, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
    .page__content-wrapper {
      width: 65%;
      float: left;
      margin-left: 0.83333%;
      margin-right: 0.83333%;
      margin-left: 0.83333%; } }
  @media (min-width: 981px) {
    /* line 62, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
    .page__content-wrapper {
      width: 73.33333%;
      float: left;
      margin-left: 0.83333%;
      margin-right: 0.83333%;
      margin-left: 0.83333%; } }

/* line 81, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__content {
  width: 98.33333%;
  float: left;
  margin-left: 0.83333%;
  margin-right: 0.83333%; }
  /* line 84, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
  .page__content img + img {
    margin-top: 1.6rem; }
  /* line 88, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
  .page__content h2 {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.9rem;
    color: #000; }
  /* line 96, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
  .page__content p,
  .page__content div {
    margin-bottom: 1.6rem;
    font-family: "pt_serifregular", serif;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #000; }
  /* line 105, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
  .page__content a:not([class]) {
    color: #003da5; }
    /* line 108, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
    .page__content a:not([class]):hover {
      font-style: italic; }

/* line 114, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__list {
  margin-bottom: 1.6rem; }

/* line 118, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__list-item {
  font-family: "pt_serifregular", serif;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: #000; }

/* line 125, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__list-item-link {
  color: #003da5; }
  /* line 128, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
  .page__list-item-link:hover {
    font-style: italic; }

/* line 133, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__column-primary {
  width: 100%;
  float: left;
  padding-left: 0.83333%;
  padding-right: 0.83333%; }
  @media (min-width: 771px) {
    /* line 133, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
    .page__column-primary {
      width: 25%;
      float: left;
      padding-left: 0.83333%;
      padding-right: 0.83333%; } }

/* line 141, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__column-secondary {
  width: 100%;
  float: left;
  padding-left: 0.83333%;
  padding-right: 0.83333%; }
  @media (min-width: 771px) {
    /* line 141, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
    .page__column-secondary {
      width: 50%;
      float: left;
      padding-left: 0.83333%;
      padding-right: 0.83333%; } }
  /* line 149, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
  .page__column-secondary .page__divider:last-of-type {
    display: none; }

/* line 155, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__column-tertiary {
  width: 100%;
  float: left;
  padding-left: 0.83333%;
  padding-right: 0.83333%; }
  @media (min-width: 771px) {
    /* line 155, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
    .page__column-tertiary {
      width: 25%;
      float: left;
      padding-left: 0.83333%;
      padding-right: 0.83333%; } }

/* line 163, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__column-tertiary-partial {
  width: 100%;
  float: left;
  padding-left: 0.83333%;
  padding-right: 0.83333%;
  margin-bottom: 1.6rem; }
  @media (min-width: 771px) {
    /* line 163, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
    .page__column-tertiary-partial {
      width: 50%;
      float: left;
      padding-left: 0.83333%;
      padding-right: 0.83333%; } }

/* line 172, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__post-title {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.9rem;
  color: #ff8200; }

/* line 180, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__post-link {
  width: 100%;
  float: left;
  padding-left: 0.83333%;
  padding-right: 0.83333%;
  margin-bottom: 1.6rem;
  color: #ff8200; }
  /* line 185, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
  .page__post-link:hover {
    font-style: italic; }

/* line 190, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__post-archive {
  width: 100%;
  float: left;
  margin-bottom: 1.6rem; }

/* line 195, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__post-archive-item {
  width: 100%;
  float: left;
  padding-left: 0.83333%;
  padding-right: 0.83333%;
  padding-left: 0; }

/* line 200, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__post-archive-title {
  width: 100%;
  float: left;
  padding-left: 0.83333%;
  padding-right: 0.83333%;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.9rem;
  color: #ff8200; }
  /* line 208, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
  .page__post-archive-title:hover {
    font-style: italic; }

/* line 213, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__post-archive-year {
  width: 100%;
  float: left;
  padding-left: 0.83333%;
  padding-right: 0.83333%;
  margin-top: 1.6rem;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.9rem;
  color: #000; }

/* line 223, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__post-archive-month {
  width: 100%;
  float: left;
  padding-left: 0.83333%;
  padding-right: 0.83333%;
  font-family: "pt_serifregular", serif;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: #ff8200; }
  /* line 230, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
  .page__post-archive-month:hover {
    font-style: italic; }

/* line 235, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
.page__side-link {
  width: 100%;
  float: left;
  padding-left: 0.83333%;
  padding-right: 0.83333%;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.9rem;
  color: #ff8200; }
  /* line 243, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_page.scss */
  .page__side-link:hover {
    font-style: italic; }

/* line 1, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_primary-navigation.scss */
.primary-navigation {
  width: 100%;
  float: left;
  padding-left: 0.83333%;
  padding-right: 0.83333%;
  position: relative;
  padding: 0 0.6rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s ease-in-out; }
  @media (min-width: 601px) {
    /* line 1, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_primary-navigation.scss */
    .primary-navigation {
      width: 25%;
      float: left;
      padding-left: 0.83333%;
      padding-right: 0.83333%;
      max-height: none;
      padding: 0; } }
  @media (min-width: 771px) {
    /* line 1, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_primary-navigation.scss */
    .primary-navigation {
      width: 23.33333%;
      float: left;
      margin-left: 0.83333%;
      margin-right: 0.83333%; } }
  /* line 19, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_primary-navigation.scss */
  .primary-navigation--open {
    max-height: 20rem; }
  /* line 23, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_primary-navigation.scss */
  .primary-navigation__item {
    width: 100%;
    float: left;
    padding-left: 0.83333%;
    padding-right: 0.83333%;
    position: relative;
    margin-bottom: 0.4rem;
    padding: 0.4rem 0.8rem;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #fff;
    background-color: #003da5; }
    @media (min-width: 601px) {
      /* line 23, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_primary-navigation.scss */
      .primary-navigation__item {
        padding: 0;
        color: #000;
        background-color: transparent; } }
    /* line 40, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_primary-navigation.scss */
    .primary-navigation__item:hover {
      font-style: italic; }
    /* line 44, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_primary-navigation.scss */
    .primary-navigation__item--current {
      background-color: #7c8ca3; }
      @media (min-width: 601px) {
        /* line 44, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_primary-navigation.scss */
        .primary-navigation__item--current {
          color: #003da5;
          border-bottom: 0.1rem solid #003da5;
          background-color: transparent; } }
  /* line 55, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_primary-navigation.scss */
  .primary-navigation__item-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  /* line 63, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_primary-navigation.scss */
  .primary-navigation__toggle-open {
    width: 2.3rem;
    height: 1.7rem;
    opacity: 1;
    transition: opacity .3s ease-in-out .3s; }
    @media (min-width: 601px) {
      /* line 63, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_primary-navigation.scss */
      .primary-navigation__toggle-open {
        display: none; } }
    /* line 73, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_primary-navigation.scss */
    .primary-navigation__toggle-open--hidden {
      opacity: 0;
      transition: opacity 0s ease-in-out; }
  /* line 79, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_primary-navigation.scss */
  .primary-navigation__toggle-close {
    float: right;
    margin-top: 0.8rem;
    margin-right: 0.5rem;
    width: 1.8rem;
    height: 1.8rem; }
    @media (min-width: 601px) {
      /* line 79, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_primary-navigation.scss */
      .primary-navigation__toggle-close {
        display: none; } }

/* line 2, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_secondary-navigation.scss */
.secondary-navigation--primary {
  width: 48.33333%;
  float: left;
  margin-left: 0.83333%;
  margin-right: 0.83333%;
  position: relative;
  margin-top: 0.8rem;
  padding: 0; }
  @media (min-width: 601px) {
    /* line 2, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_secondary-navigation.scss */
    .secondary-navigation--primary {
      display: none; } }

/* line 13, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_secondary-navigation.scss */
.secondary-navigation--secondary {
  display: none; }
  @media (min-width: 771px) {
    /* line 13, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_secondary-navigation.scss */
    .secondary-navigation--secondary {
      width: 31.66667%;
      float: left;
      margin-left: 0.83333%;
      margin-right: 0.83333%;
      display: block; } }
  @media (min-width: 981px) {
    /* line 13, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_secondary-navigation.scss */
    .secondary-navigation--secondary {
      width: 15%;
      float: left;
      margin-left: 0.83333%;
      margin-right: 0.83333%;
      margin-left: -15.83333%;
      position: absolute; } }

/* line 28, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_secondary-navigation.scss */
.secondary-navigation__item {
  width: 100%;
  float: left;
  position: relative;
  margin-bottom: 0.4rem;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: #000; }
  /* line 37, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_secondary-navigation.scss */
  .secondary-navigation__item:hover {
    font-style: italic; }
  /* line 41, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_secondary-navigation.scss */
  .secondary-navigation__item--current {
    color: #003da5; }
    @media (min-width: 771px) {
      /* line 41, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_secondary-navigation.scss */
      .secondary-navigation__item--current {
        border-bottom: 0.1rem solid #003da5; } }

/* line 50, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_secondary-navigation.scss */
.secondary-navigation__item-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

/* line 58, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_secondary-navigation.scss */
.secondary-navigation__toggle {
  width: 100%;
  float: left;
  padding-left: 0.83333%;
  padding-right: 0.83333%;
  position: relative;
  padding: 0.4rem 0.8rem;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #fff;
  text-align: left;
  background-color: #003da5; }
  @media (min-width: 771px) {
    /* line 58, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_secondary-navigation.scss */
    .secondary-navigation__toggle {
      display: none; } }
  /* line 73, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_secondary-navigation.scss */
  .secondary-navigation__toggle:before {
    content: '+';
    display: inline-block;
    margin-right: 0.8rem;
    transition: transform .3s ease-in-out; }
  /* line 80, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_secondary-navigation.scss */
  .secondary-navigation__toggle--open:before {
    transform: rotate(45deg); }

/* line 85, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_secondary-navigation.scss */
.secondary-navigation__list {
  width: 100%;
  float: left;
  padding-left: 0.83333%;
  padding-right: 0.83333%;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 2.6rem;
  padding: 0.8rem;
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  transition: max-height .3s ease-in-out; }
  @media (min-width: 601px) {
    /* line 85, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_secondary-navigation.scss */
    .secondary-navigation__list {
      background-color: transparent;
      max-height: none;
      visibility: visible; } }
  /* line 102, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_secondary-navigation.scss */
  .secondary-navigation__list--open {
    max-height: 50rem;
    visibility: visible;
    z-index: 2; }

/* line 1, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_share.scss */
.share {
  margin-bottom: 1.6rem; }
  /* line 4, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_share.scss */
  .share__item {
    display: inline-block;
    font-family: "pt_serifregular", serif;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #ff8200;
    margin-right: 1.6rem; }
    /* line 12, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_share.scss */
    .share__item:last-child {
      margin-right: 0; }
  /* line 17, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_share.scss */
  .share__item-link {
    color: #ff8200; }
    /* line 20, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_share.scss */
    .share__item-link:hover {
      font-style: italic; }

/* line 1, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_pagination.scss */
.pagination {
  width: 100%;
  float: left;
  margin: 0.8rem 0 1.6rem; }
  /* line 5, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_pagination.scss */
  .pagination__item {
    width: 50%;
    float: left;
    font-family: "pt_serifregular", serif;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #000;
    -webkit-font-smoothing: antialiased; }
    /* line 13, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_pagination.scss */
    .pagination__item:nth-child(even) {
      text-align: right; }
    /* line 18, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_pagination.scss */
    .pagination__item--divided:nth-child(2):before {
      content: '|';
      margin: 0 1.6rem;
      color: #000; }
  /* line 27, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_pagination.scss */
  .pagination__item-link:hover {
    font-style: italic; }
  /* line 31, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_pagination.scss */
  .pagination__item-link--primary {
    color: #003da5; }
  /* line 35, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_pagination.scss */
  .pagination__item-link--secondary {
    color: #ff8200; }

/* line 2, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_comment.scss */
.comment__item {
  width: 100%;
  float: left;
  padding-left: 0.83333%;
  padding-right: 0.83333%;
  margin-bottom: 1.6rem;
  font-family: "pt_serifregular", serif;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: #000; }

/* line 11, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_comment.scss */
.comment__form {
  width: 100%;
  float: left;
  padding-left: 0.83333%;
  padding-right: 0.83333%; }
  /* line 14, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_comment.scss */
  .comment__form label {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.9rem;
    color: #000; }
  /* line 22, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_comment.scss */
  .comment__form input,
  .comment__form textarea {
    margin-bottom: 1.6rem; }

/* line 28, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_comment.scss */
.comment__popup {
  position: relative;
  width: 20rem;
  padding: 1.6rem;
  background-color: #ff8200;
  color: #fff;
  position: absolute;
  right: 1.2rem;
  top: 60rem; }
  /* line 38, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_comment.scss */
  .comment__popup h2 {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.9rem;
    color: #fff; }
  /* line 46, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_comment.scss */
  .comment__popup p {
    margin-bottom: 1.6rem;
    font-family: "pt_serifregular", serif;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #fff; }

/* line 55, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_comment.scss */
.comment__return {
  width: 100%;
  float: left;
  padding-left: 0.83333%;
  padding-right: 0.83333%;
  margin: 1.6rem 0;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.9rem;
  color: #ff8200; }
  /* line 64, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_comment.scss */
  .comment__return:hover {
    font-style: italic; }

/* line 69, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_comment.scss */
.comment__popup-close {
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.9rem;
  color: #fff; }

/* line 1, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_carousel.scss */
.carousel {
  width: 98.33333%;
  float: left;
  margin-left: 0.83333%;
  margin-right: 0.83333%;
  position: relative; }
  /* line 5, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_carousel.scss */
  .carousel__navigation {
    position: absolute;
    top: 53%;
    right: 0;
    left: 0;
    transform: translateY(-50%); }
  /* line 13, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_carousel.scss */
  .carousel__navigation-previous {
    width: 48.33333%;
    float: left;
    margin-left: 0.83333%;
    margin-right: 0.83333%; }
  /* line 17, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_carousel.scss */
  .carousel___navigation-next {
    width: 48.33333%;
    float: left;
    margin-left: 0.83333%;
    margin-right: 0.83333%;
    text-align: right; }
  /* line 22, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_carousel.scss */
  .carousel__dot-container {
    position: absolute;
    right: 1.6rem;
    bottom: 1.6rem;
    left: 1.6rem; }
  /* line 29, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_carousel.scss */
  .carousel__dot-item {
    float: left;
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.8rem;
    border-radius: 50%;
    background-color: #e6e6e6; }
    /* line 37, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_carousel.scss */
    .carousel__dot-item:last-of-type {
      margin-right: 0; }
    /* line 41, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_carousel.scss */
    .carousel__dot-item.active {
      background-color: #003da5; }
  /* line 46, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_carousel.scss */
  .carousel__control {
    display: block;
    position: relative;
    width: 3rem;
    height: 3rem;
    color: #fff;
    background-color: #003da5;
    border-radius: 50%; }
    /* line 55, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_carousel.scss */
    .carousel__control:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 2.1rem;
      line-height: 2.4rem;
      transform: translateY(-50%) translateX(-50%); }
    /* line 66, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_carousel.scss */
    .carousel__control--previous {
      margin-left: 1rem; }
      /* line 69, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_carousel.scss */
      .carousel__control--previous:before {
        content: '<'; }
    /* line 74, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_carousel.scss */
    .carousel__control--next {
      float: right;
      margin-right: 1rem; }
      /* line 78, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_carousel.scss */
      .carousel__control--next:before {
        content: '>'; }
  /* line 84, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_carousel.scss */
  .carousel__control-text {
    position: absolute;
    left: 999.9rem; }
  /* line 90, /Users/joemarkevicius/Sites/patersonas/Paterson/assets/src/styles/sass/_modules/_carousel.scss */
  .carousel__media--decorated {
    border: 0.1rem solid #003da5; }
