.secondary-navigation {
  &--primary {
    @include span(6 of 12);
    position: relative;
    margin-top: rem(8px);
    padding: 0;

    @include ml-up {
      display: none;
    }
  }

  &--secondary {
    display: none;

    @include lg-up {
      @include span(4 of 12);
      display: block;
    }

    @include xl-up {
      @include span(2 of 12);
      @include pull(2 of 12);
      position: absolute;
    }
  }

  &__item {
    @include span(12 of 12 inside no-gutters);
    position: relative;
    margin-bottom: rem(4px);
    font-family: $font-primary;
    font-size: rem(14px);
    line-height: rem(19px);
    color: $color-black;

    &:hover {
      font-style: italic;
    }

    &--current {
      color: $color-cobalt;

      @include lg-up {
        border-bottom: rem(1px) solid $color-cobalt;
      }
    }
  }

  &__item-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__toggle {
    @include span(12 of 12 inside);
    position: relative;
    padding: rem(4px) rem(8px);
    font-family: $font-primary;
    font-size: rem(14px);
    line-height: rem(18px);
    color: $color-white;
    text-align: left;
    background-color: $color-cobalt;

    @include lg-up {
      display: none;
    }

    &:before {
      content: '+';
      display: inline-block;
      margin-right: rem(8px);
      transition: transform .3s ease-in-out;
    }

    &--open:before {
      transform: rotate(45deg);
    }
  }

  &__list {
    @include span(12 of 12 inside);
    background-color: rgba($color-white, .8);
    position: absolute;
    top: rem(26px);
    padding: rem(8px);
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: max-height .3s ease-in-out;

    @include ml-up {
      background-color: transparent;
      max-height: none;
      visibility: visible;
    }

    &--open {
      max-height: rem(500px);
      visibility: visible;
      z-index: 2;
    }
  }
}
