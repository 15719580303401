@mixin clearfix {
  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}


@mixin sm-up {
  @media (min-width: #{$sm-min}) {
    @content;
  }
}


@mixin ml-up {
  @media (min-width: #{$ml-min}) {
    @content;
  }
}


@mixin lg-up {
  @media (min-width: #{$lg-min}) {
    @content;
  }
}


@mixin xl-up {
  @media (min-width: #{$xl-min}) {
    @content;
  }
}


@mixin xxl-up {
  @media (min-width: #{$xxl-min}) {
    @content;
  }
}


@mixin sm-down {
  @media (max-width: #{$xs_max}) {
    @content;
  }
}


@mixin ml-down {
  @media (max-width: #{$sm_max}) {
    @content;
  }
}


@mixin lg-down {
  @media (max-width: #{$ml_max}) {
    @content;
  }
}


@mixin xl-down {
  @media (max-width: #{$lg_max}) {
    @content;
  }
}


@mixin xxl-down {
  @media (max-width: #{$xl_max}) {
    @content;
  }
}

// Blue
@mixin pattern-dot-primary {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4IDgiPiAgPGNpcmNsZSBjeD0iMiIgY3k9IjIiIHI9IjIiIGZpbGw9IiMxYTQ1OWQiLz48L3N2Zz4=);
}

// Orange
@mixin pattern-dot-secondary {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4IDgiPiAgPGNpcmNsZSBjeD0iMiIgY3k9IjIiIHI9IjIiIGZpbGw9IiNmZjgyMDAiLz48L3N2Zz4=);
}
