$pixels: false;
$font-size: 10px;
$xs-min: 0;
$xs-max: 320px;
$sm-min: 321px;
$sm-max: 600px;
$ml-min: 601px;
$ml-max: 770px;
$lg-min: 771px;
$lg-max: 980px;
$xl-min: 981px;
$xl-max: 1200px;
$xxl-min: 1201px;
$susy:(
  columns: 12,
  container: 980px,
  gutter: 0,
  gutter-position: split,
  global-box-sizing: border-box,
);
$transition-primary: cubic-bezier(.4, 0, .2, 1);
