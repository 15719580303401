.pagination {
  @include span(12 of 12 inside no-gutters);
  margin: rem(8px) 0 rem(16px);

  &__item {
    @include span(6 of 12 inside no-gutters);
    font-family: $font-secondary;
    font-size: rem(14px);
    line-height: rem(19px);
    color: $color-black;
    -webkit-font-smoothing: antialiased;

    &:nth-child(even) {
      text-align: right;
    }

    &--divided {
      &:nth-child(2):before {
        content: '|';
        margin: 0 rem(16px);
        color: $color-black;
      }
    }
  }

  &__item-link {
    &:hover {
      font-style: italic;
    }

    &--primary {
      color: $color-cobalt;
    }

    &--secondary {
      color: $color-flush-orange;
    }
  }
}
