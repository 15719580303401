* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  font-size: $font-size;
  height: 100%;
}

img {
  height: auto;
  width: 100%;
}

h2,
p {
  -webkit-font-smoothing: antialiased;
}

button {
    background: none;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    appearance: none;
}
