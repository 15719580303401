.primary-navigation {
  @include span(12 of 12 inside);
  position: relative;
  padding: 0 rem(6px);
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s ease-in-out;

  @include ml-up {
    @include span(3 of 12 inside);
    max-height: none;
    padding: 0;
  }

  @include lg-up {
    @include span(3 of 12);
  }

  &--open {
    max-height: rem(200px);
  }

  &__item {
    @include span(12 of 12 inside);
    position: relative;
    margin-bottom: rem(4px);
    padding: rem(4px) rem(8px);
    font-family: $font-primary;
    font-size: rem(14px);
    line-height: rem(19px);
    color: $color-white;
    background-color: $color-cobalt;

    @include ml-up {
      padding: 0;
      color: $color-black;
      background-color: transparent;
    }

    &:hover {
      font-style: italic;
    }

    &--current {
      background-color: $color-regent-gray;

      @include ml-up {
        color: $color-cobalt;
        border-bottom: rem(1px) solid $color-cobalt;
        background-color: transparent;
      }
    }
  }

  &__item-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__toggle-open {
    width: rem(23px);
    height: rem(17px);
    opacity: 1;
    transition: opacity .3s ease-in-out .3s;

    @include ml-up {
      display: none;
    }

    &--hidden {
      opacity: 0;
      transition: opacity 0s ease-in-out;
    }
  }

  &__toggle-close {
    float: right;
    margin-top: rem(8px);
    margin-right: rem(5px);
    width: rem(18px);
    height: rem(18px);

    @include ml-up {
      display: none;
    }
  }
}
